import { Contract } from 'ethers';

export const getRateOut = async (
  priceRateContract: Contract,
  tokenAmount: number
) => {
  const data = await priceRateContract.getFunction('getRateOut')(
    BigInt(tokenAmount) * BigInt(`1000000000000000000`) // 10 ** 18
  );
  console.log('data', data);

  // remove floating point errors
  return Math.round((Number(data) / 10 ** 18) * 1000) / 1000;
};
