'use client';

import { useEffect, useState } from 'react';

import { chain, chainParams } from '../../web3/chain';
import { metaMask } from '../../web3/connectors/metaMask';
import { walletConnect } from '../../web3/connectors/walletConnect';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';

export function useConnect() {
  const { chainId, isActive, provider } = useWeb3React();

  const [isConnectLoading, setIsConnectLoading] = useState(false);

  const handleConnect = async (connector: 'Metamask' | 'WalletConnect') => {    
    localStorage.setItem('connector', connector);
    try {
      setIsConnectLoading(true);
      if (connector === 'Metamask') {
        await metaMask.activate({ ...chainParams, chainId: chain.chainId });
      } else {
        await walletConnect.activate(chain.chainId);
      }
    } catch (error) {
      if ((error as Error)?.message === 'metaMask is not defined') {
        window.open(
          `https://metamask.app.link/dapp/${window.location.host}/`,
          '_blank'
        );
      }
    } finally {
      setIsConnectLoading(false);
    }
  };

  const handleDisconnect = async () => {
    try {
      if (localStorage.getItem('walletconnect')) {
        await walletConnect.deactivate();
        localStorage.removeItem('walletconnect');
      } else {
        if (metaMask.deactivate) {
          await metaMask.deactivate();
        }
        await metaMask.resetState();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const createProviderNetwork = async (networkProvider: Web3Provider) => {
      try {
        await networkProvider.send('wallet_addEthereumChain', [chainParams]);
      } catch {
        handleDisconnect();
      }
    };

    const switchProviderNetwork = async (networkProvider: Web3Provider) => {
      try {
        setIsConnectLoading(true);
        await networkProvider.send('wallet_switchEthereumChain', [
          { chainId: chainParams.chainId },
        ]);
      } catch (error) {
        if ((error as Error & { code?: number })?.code === 4902) {
          await createProviderNetwork(networkProvider);
        }
      } finally {
        setIsConnectLoading(false);
      }
    };

    if (isActive && provider && chain.chainId !== chainId) {
      switchProviderNetwork(provider);
    }
  }, [isActive, chainId, provider]);

  useEffect(() => {
    if (localStorage.getItem('walletconnect')) {
      walletConnect.connectEagerly();
    } else {
      metaMask.connectEagerly();
    }
  }, []);

  return { handleConnect, handleDisconnect, isConnectLoading };
}
