import { FC, useEffect, useState } from 'react';
import { Chart } from '../chart';
import styles from './graphic.module.scss';
import { BuyTokenButtons } from '../buyTokenButtons';
import { useGraphicData } from './graphic.hook';
import { useWeb3React } from '@web3-react/core';
import { useInvestTokenContract, useSaleContract } from '../../../../../../hooks/web3/useContract';
import { getBuyBackTime } from '../../../../../../utils/web3/getBuyBackTime';
import { getUserInvestTokenBalance } from '../../../../../../utils/web3/getUserInvestTokenBalance'

interface GraphicProps {
  handleOpenBuyModal: () => void;
  handleOpenSellModal: () => void;
}

export const Graphic: FC<GraphicProps> = ({
  handleOpenBuyModal,
  handleOpenSellModal,
}) => {
  const { data, currentPrice } = useGraphicData();
  const [isSellEnabled, setSellEnabled] = useState(false);
  const { account } = useWeb3React();
  const saleContract = useSaleContract();
  const investTokenContract = useInvestTokenContract();

  useEffect(() => {
    const init = async () => {
      if (!account || !saleContract || !investTokenContract) {
        return;
      }
      const buyBackTime = await getBuyBackTime(saleContract, account);
      const balanceOf = await getUserInvestTokenBalance(
        investTokenContract,
        account
      );      
      
      const isEnabled = (Date.now() > buyBackTime * 1000) && balanceOf > 0;
      console.log(isEnabled);
      
      setSellEnabled(isEnabled);
      if (isEnabled) {
        return;
      }
      setTimeout(() => {
        setSellEnabled(balanceOf > 0);
      }, buyBackTime * 1000 - Date.now());
    };

    init().catch(console.error);
  }, [account, saleContract, investTokenContract]);
 
  return (
    <div className={styles.wrapper}>
      <Chart data={data} />
      <div className={styles.buttonsWrapper}>
        <BuyTokenButtons
          onBuyToken={handleOpenBuyModal}
          onSellToken={handleOpenSellModal}
          tokenPrice={currentPrice}
          isSellEnabled={isSellEnabled}
        />
      </div>
    </div>
  );
};
